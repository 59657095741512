import * as i0 from '@angular/core';
import { ElementRef, ChangeDetectorRef, Component, Inject, Input, NgModule, Optional } from '@angular/core';
const _c0 = ["*"];
class OptionsProvider {
  constructor(options) {
    this.options = options;
  }
}
function uppercamelcase(str) {
  return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, firstLetter => {
    return firstLetter.toUpperCase();
  }).replace(/[-_]/g, '');
}
class TablerIconComponent {
  constructor(elem, changeDetector, options) {
    this.elem = elem;
    this.changeDetector = changeDetector;
    this.options = options;
  }
  ngOnChanges(changes) {
    // icons are provided as an array of objects because of "multi: true"
    const icons = Object.assign({}, ...this.options.map(option => option.icons));
    const svg = icons[`Icon${uppercamelcase(changes.name.currentValue)}`] ?? '';
    if (!svg && !this.options.some(option => option.ignoreWarnings)) {
      console.warn(`Tabler Icon not found: ${changes.name.currentValue}\n` + `Refer to documentation on https://github.com/pierreavn/angular-tabler-icons`);
    }
    this.elem.nativeElement.innerHTML = svg;
    this.changeDetector.markForCheck();
  }
}
TablerIconComponent.ɵfac = function TablerIconComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TablerIconComponent)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(OptionsProvider));
};
TablerIconComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TablerIconComponent,
  selectors: [["i-tabler"], ["tabler-icon"]],
  inputs: {
    name: "name"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function TablerIconComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  styles: ["[_nghost-%COMP%]{display:inline-block;width:24px;height:24px;fill:none;stroke:currentColor;stroke-width:2px;stroke-linecap:round;stroke-linejoin:round}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TablerIconComponent, [{
    type: Component,
    args: [{
      selector: 'i-tabler, tabler-icon',
      template: "<ng-content></ng-content>\n",
      styles: [":host{display:inline-block;width:24px;height:24px;fill:none;stroke:currentColor;stroke-width:2px;stroke-linecap:round;stroke-linejoin:round}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [OptionsProvider]
      }]
    }];
  }, {
    name: [{
      type: Input
    }]
  });
})();
class TablerIconsModule {
  constructor(options) {
    this.options = options;
    if (!this.options) {
      throw new Error(`No icon provided. Make sure to use 'TablerIconsModule.pick({ ... })' when importing the module\n` + `Refer to documentation on https://github.com/pierreavn/angular-tabler-icons`);
    }
  }
  /**
   * Initialize module with given icons and options
   * @param icons
   * @returns Module with options
   */
  static pick(icons, options) {
    return {
      ngModule: TablerIconsModule,
      providers: [{
        provide: OptionsProvider,
        useValue: {
          icons,
          ...options
        },
        multi: true
      }]
    };
  }
}
TablerIconsModule.ɵfac = function TablerIconsModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TablerIconsModule)(i0.ɵɵinject(OptionsProvider, 8));
};
TablerIconsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TablerIconsModule
});
TablerIconsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TablerIconsModule, [{
    type: NgModule,
    args: [{
      declarations: [TablerIconComponent],
      exports: [TablerIconComponent]
    }]
  }], function () {
    return [{
      type: OptionsProvider,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
class TablerIconsProvider {
  static pick(icons, options) {
    return {
      provide: OptionsProvider,
      useValue: {
        icons,
        ...options
      },
      multi: true
    };
  }
}

/*
 * Public API Surface of angular-tabler-icons
 */

/**
 * Generated bundle index. Do not edit.
 */

export { TablerIconComponent, TablerIconsModule, TablerIconsProvider };
