import { Injectable } from '@angular/core'
import { ToastNotification } from '@lib/definitions/toast-notification.definition'

/**
 * Servicio que contiene la lógica para mostrar notificaciones
 */
@Injectable({
	providedIn: 'root',
})
export class ToastNotificationsService {
	private _toastNotifications: ToastNotification[] = []
	private readonly MAX_NOTIFICATIONS: number = 5 // Define el límite máximo de notificaciones

	get toastNotifications(): ToastNotification[] {
		return this._toastNotifications
	}

	create(toastNotification: ToastNotification): void {
		toastNotification.id = this._toastNotifications.length + 1
		toastNotification.isVisible = true

		if (this._toastNotifications.length >= this.MAX_NOTIFICATIONS) {
			this._toastNotifications[this._toastNotifications.length - this.MAX_NOTIFICATIONS].isVisible = false
		}

		this._toastNotifications.push(toastNotification)
		this.sortList()
	}

	remove(toastNotification: ToastNotification): void {
		toastNotification.isVisible = false

		if (this._toastNotifications.every((toastNotification): boolean => toastNotification.isVisible === false)) {
			setTimeout((): void => {
				this._toastNotifications = []
			}, 500)
		}

		this.sortList()
	}

	private sortList(): void {
		let visibleCounter: number = 0

		this._toastNotifications.forEach((toastNotification): void => {
			if (toastNotification.isVisible) {
				toastNotification.position = visibleCounter
				visibleCounter++
			} else {
				toastNotification.position = -1 // Opcional: indica que no está en la cola visible
			}
		})
	}
}
