import { Component, InputSignal, OnInit, inject, input } from '@angular/core'
import { NgClass } from '@angular/common'

import { ToastNotificationsService } from '@lib/services/notifications/toast-notifications.service'
import { ToastNotification } from '@lib/definitions/toast-notification.definition'

@Component({
	selector: 'app-toast-notification',
	standalone: true,
	imports: [NgClass],
	template: `
		<div
			aria-live="assertive"
			class="pointer-events-none fixed inset-0 z-50 flex items-start px-4 py-6 sm:items-start sm:p-6"
		>
			<div
				style="padding-top: {{ notification().position! * 30 }}px;"
				class="flex w-full flex-col items-center space-y-4 transition-all sm:items-end"
			>
				<div
					[ngClass]="{
						'animate-fade-out-up': !notification().isVisible,
					}"
					class="pointer-events-auto w-full max-w-sm animate-fade-in-up overflow-hidden rounded-lg border bg-white shadow-lg ring-1 ring-black ring-opacity-5"
				>
					<div class="p-4">
						<div class="flex items-start">
							<div class="flex-shrink-0">
								<i
									class="h-6 w-6"
									[ngClass]="{
										'i-tabler-circle-check text-green-500': notification().type === 'success',
										'i-tabler-circle-x text-red-500': notification().type === 'error',
										'i-tabler-info-circle text-blue-500': notification().type === 'info',
										'i-tabler-alert-triangle text-yellow-500': notification().type === 'warning',
									}"
								></i>
							</div>
							<div class="ml-3 w-0 flex-1">
								<p class="text-sm text-gray-500">{{ notification().message }}</p>
							</div>
							<div class="ml-4 flex flex-shrink-0">
								<button
									(click)="onClose()"
									type="button"
									class="group inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								>
									<span class="sr-only">Close</span>
									<i class="i-tabler-x transition-transform group-hover:rotate-180"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
})
export class ToastNotificationComponent implements OnInit {
	notification: InputSignal<ToastNotification> = input.required<ToastNotification>()
	private readonly TOAST_DURATION: number = 5000
	private timer!: any

	toastNotificationsService: ToastNotificationsService = inject(ToastNotificationsService)

	ngOnInit(): void {
		// Iniciamos el timer si existe el tiempo en ms
		this.setTimer()
	}

	onClose(): void {
		this.close()
	}

	private setTimer(): void {
		this.timer = setTimeout((): void => {
			this.close()
		}, this.TOAST_DURATION)
	}

	private close(): void {
		clearTimeout(this.timer) // Limpiamos el timer si existe
		this.toastNotificationsService.remove(this.notification())
	}
}
