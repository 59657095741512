import { Injectable } from '@angular/core'
import { Analytics } from '@angular/fire/analytics'
import { FirebaseApp } from '@angular/fire/app'
import { NavigationEnd, Router } from '@angular/router'
import { environment } from '@environments/environment'
import { getAnalytics, logEvent, setAnalyticsCollectionEnabled, setUserProperties } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { filter } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	private analytics!: Analytics

	constructor(private router: Router) {
		this.initializeAnalytics()
		this.trackPageViews()
	}

	private initializeAnalytics(): void {
		const app: FirebaseApp = initializeApp(environment.firebaseConfig) // Asegura que Firebase App esté inicializada
		this.analytics = getAnalytics(app)
		if (!environment.production) {
			// Deshabilita la recopilación de datos en desarrollo
			setAnalyticsCollectionEnabled(this.analytics, false)
			console.log('Google Analytics está en modo de prueba (desarrollo).')
		}

		// Establece propiedades personalizadas para diferenciar entre entornos
		setUserProperties(this.analytics, {
			environment: environment.production ? 'production' : 'development',
		})
	}

	// Método para registrar eventos
	logEvent(eventName: string, eventParams?: { [key: string]: any }): void {
		logEvent(this.analytics, eventName, eventParams)
	}

	private trackPageViews(): void {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
			logEvent(this.analytics, 'page_view', { page_path: event.urlAfterRedirects })
		})
	}
}
