import { Component, inject } from '@angular/core'
import { ToastNotificationComponent } from '@components/notifications/toast-notification/toast-notification.component'
import { ToastNotificationsService } from '@lib/services/notifications/toast-notifications.service'

@Component({
	selector: 'app-toast-notifications-portal',
	standalone: true,
	imports: [ToastNotificationComponent],
	template: `
		@for (item of toastNotificationsService.toastNotifications; track item.id) {
			<app-toast-notification [notification]="item" />
		}
	`,
})
export class ToastNotificationsPortalComponent {
	toastNotificationsService: ToastNotificationsService = inject(ToastNotificationsService)
}
