// src/presets/aura/overlaybadge/index.ts
var overlaybadge_default = {
  root: {
    outline: {
      width: "2px",
      color: "{content.background}"
    }
  }
};
export { overlaybadge_default as default };
