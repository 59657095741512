import { HttpErrorResponse, HttpHandler, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http'
import { inject } from '@angular/core'
import { MessageService } from 'primeng/api'
import {
	catchError,
	firstValueFrom,
	from,
	fromEvent,
	lastValueFrom,
	map,
	merge,
	mergeMap,
	Observable,
	Observer,
	tap,
} from 'rxjs'

export const internetConnectionInterceptor: HttpInterceptorFn = (req, next) => {
	return from(handle(req, next))
	// .pipe
	// // catchError(async (err: HttpErrorResponse) => {
	// //   throw err
	// // })
	// ()
}

const handle = async (req: HttpRequest<any>, next: HttpHandlerFn) => {
	const toast = inject(MessageService)
	const online = await firstValueFrom(
		merge(
			fromEvent(window, 'offline').pipe(map(() => false)),
			fromEvent(window, 'online').pipe(map(() => true)),
			new Observable((sub: Observer<boolean>) => {
				sub.next(navigator.onLine)
				sub.complete()
			})
		)
	)
	if (!online) {
		toast.add({
			severity: 'error',
			life: 5000,
			detail: 'No hay conexión a internet, intentelo de nuevo más tarde.',
			summary: 'Ha ocurrido un error.',
		})
		throw Error('No hay conexion a internet')
	}
	return lastValueFrom(next(req))
}
