// src/presets/aura/inputgroup/index.ts
var inputgroup_default = {
  addon: {
    background: "{form.field.background}",
    borderColor: "{form.field.border.color}",
    color: "{form.field.icon.color}",
    borderRadius: "{form.field.border.radius}",
    padding: "0.5rem",
    minWidth: "2.5rem"
  }
};
export { inputgroup_default as default };
