import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core'
import { provideRouter, withViewTransitions } from '@angular/router'
import { provideHttpClient, withInterceptors } from '@angular/common/http'

import { routes } from './app.routes'
import { provideHotToastConfig } from '@ngneat/hot-toast'
import { tokenInterceptor } from '@lib/interceptors/token.interceptor'
import { GlobalErrorHandler } from '@lib/handlers/global-error.handler'
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { environment } from '@environments/environment'
import { getAuth, provideAuth } from '@angular/fire/auth'
import { TablerIconsProvider } from 'angular-tabler-icons'
import * as TablerIcons from 'angular-tabler-icons/icons'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { internetConnectionInterceptor } from '@lib/interceptors/internet-connection.interceptor'
import { MessageService } from 'primeng/api'
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics'

export const appConfig: ApplicationConfig = {
	providers: [
		// Servicios básicos
		MessageService,

		// Router y animaciones
		provideRouter(routes, withViewTransitions()),
		provideAnimationsAsync(), // Usamos la versión asíncrona para optimizar
		provideHttpClient(withInterceptors([internetConnectionInterceptor, tokenInterceptor])),

		// Firebase
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideAnalytics(() => getAnalytics()),
		provideAuth(() => getAuth()),
		ScreenTrackingService, // Para seguimiento automático de pantallas
		UserTrackingService, // Para seguimiento de usuarios

		// Iconos
		TablerIconsProvider.pick(TablerIcons, {
			ignoreWarnings: true,
		}),

		// Configuración de HotToast
		provideHotToastConfig({
			position: 'top-center', // Ejemplo de configuración
			duration: 5000,
		}),

		// Manejador global de errores
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
	],
}
