import { inject, Injectable } from '@angular/core'
import { AppStore } from './../../store/AppStore'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Router } from '@angular/router'
import { environment } from '@environments/environment'
import { Auth as FirebaseAuth, Auth } from '@angular/fire/auth'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { catchError, firstValueFrom, of } from 'rxjs'
import { UserSession } from '@lib/interfaces/auth-user.interface'
import { AuthorizationService } from '../authorization/authorization.service'
import { IAuthorization } from '@lib/definitions/sidebar-item.definition'
import { IRefreshTokenResponse } from '@lib/interfaces/auth-token.interface'
/**
 * Servicio que contiene la lógica de autenticación con OAuth2
 */
@Injectable({
	providedIn: 'root',
})
export class OAuthService {
	private appStore = inject(AppStore)
	authInstance!: Auth

	constructor(
		private router: Router,
		private authFirebase: FirebaseAuth,
		private http: HttpClient,
		private authorization: AuthorizationService
	) {}

	private getReturnURL() {
		return window.location.hash ? window.location.href.replace(window.location.hash, '') : window.location.href
	}

	async azureAuth(email?: string) {
		const obj = {
			'Transaction-id': '1',
			Secuence: '1',
			endUser: email!,
		}
		const headers: HttpHeaders = new HttpHeaders(obj)
		try {
			// Comprobamos si devuelve 401
			const firstStep = await firstValueFrom(
				this.http
					.get(environment.proxyOIDC, {
						headers,
					})
					.pipe(
						catchError((el) => {
							return of(el.status === 401)
						})
					)
			)
			// Consultamos la URL de login del IDP
			if (firstStep) {
				const url = `${environment.proxyBaseUrl}/oidc-okta-1/login?from=${this.getReturnURL()}&apikey=${environment.clientId}`
				window.location.href = url
			}
			this.router.navigateByUrl('/home')
		} catch (error) {
			console.error('Error durante la autenticación con Azure:', error)
			// Manejo de errores
		}
	}

	async fetchIdToken(forceRefresh: boolean = false): Promise<string | null> {
		if (!this.authFirebase.currentUser) {
			return null
		}

		try {
			const idToken = await this.authFirebase.currentUser.getIdToken(forceRefresh)
			return idToken
		} catch (error) {
			console.error('Error al obtener el ID token:', error)
			return null // Propaga el error para manejarlo donde sea necesario
		}
	}

	setUserData(user: IAuthorization, idToken?: string) {
		// Aquí puedes realizar acciones adicionales con el ID token
		this.appStore.setUserSession({
			...user,
		})
	}

	async validateUserSession() {
		try {
			const sessionData = JSON.parse(localStorage.getItem('userSession')!)
			if (!sessionData) return false
			const res = await firstValueFrom(
				this.http.post(`${environment.proxyBaseUrl}/portalweb-oauth2-cc-pg-protected/protected`, sessionData)
			)
			this.setUserData(sessionData)
			return true
		} catch (error) {
			console.log(error)
			localStorage.clear()
			return false
		}
	}

	async refreshToken() {
		const user = this.appStore.userInfo()
		const token: IRefreshTokenResponse = await this.getNewAccessToken({
			'refresh-token': user?.refreshToken!,
		})

		const userInfo: IAuthorization = JSON.parse(localStorage.getItem('userSession')!)
		userInfo.refreshToken = token.refresh_token
		userInfo.accessToken = token.access_token
		userInfo.loginDate = new Date().getTime()
		userInfo.desired_apigee_token_lifetime = '600000'
		userInfo.expirationToken = (userInfo.loginDate + Number(userInfo.desired_apigee_token_lifetime)).toString()
		localStorage.setItem('userSession', JSON.stringify(userInfo))

		this.appStore.setUserSession({
			...userInfo,
		})
	}

	async getNewAccessToken(paramsData: { [key: string]: string }): Promise<IRefreshTokenResponse> {
		let headers = new HttpHeaders()
		Object.keys(paramsData).forEach((key) => {
			headers = headers.set(key, paramsData[key])
		})

		const token = await firstValueFrom(
			this.http.post<IRefreshTokenResponse>(
				`${environment.proxyBaseUrl}/apigee/portal-managment/refresh-token`,
				{},
				{ headers }
			)
		)
		return token
	}

	async logout(omitRefresh = false) {
		try {
			// await this.authFirebase.signOut()
			localStorage.clear()
			this.appStore.signOut()
			// const isSaml = this.appStore.userInfo()?.names?.includes('saml')
			// if (isSaml)
			this.signoutAzure()
			// if (!omitRefresh) window.location.reload()
		} catch (error) {
			console.error('Error al cerrar sesión:', error)
		}
	}

	signoutAzure() {
		// Redirige al usuario a la página de inicio después de cerrar sesión en Azure AD
		const postLogoutRedirectUri = encodeURIComponent(environment.redirectUri)

		// Redirige al usuario para cerrar la sesión en Azure AD y luego a la página de inicio
		window.location.href = `${environment.logoutUri}?post_logout_redirect_uri=${postLogoutRedirectUri}`
	}

	// Verifica si el usuario está autenticado
	isAuthenticated(): boolean {
		if (this.appStore.userInfo()?.accessToken) {
			return true
		}

		return false
	}
}
