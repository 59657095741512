// src/presets/aura/index.ts
import accordion from "@primeng/themes/aura/accordion";
import autocomplete from "@primeng/themes/aura/autocomplete";
import avatar from "@primeng/themes/aura/avatar";
import badge from "@primeng/themes/aura/badge";
import base from "@primeng/themes/aura/base";
import blockui from "@primeng/themes/aura/blockui";
import breadcrumb from "@primeng/themes/aura/breadcrumb";
import button from "@primeng/themes/aura/button";
import card from "@primeng/themes/aura/card";
import carousel from "@primeng/themes/aura/carousel";
import cascadeselect from "@primeng/themes/aura/cascadeselect";
import checkbox from "@primeng/themes/aura/checkbox";
import chip from "@primeng/themes/aura/chip";
import colorpicker from "@primeng/themes/aura/colorpicker";
import confirmdialog from "@primeng/themes/aura/confirmdialog";
import confirmpopup from "@primeng/themes/aura/confirmpopup";
import contextmenu from "@primeng/themes/aura/contextmenu";
import datatable from "@primeng/themes/aura/datatable";
import dataview from "@primeng/themes/aura/dataview";
import datepicker from "@primeng/themes/aura/datepicker";
import dialog from "@primeng/themes/aura/dialog";
import divider from "@primeng/themes/aura/divider";
import dock from "@primeng/themes/aura/dock";
import drawer from "@primeng/themes/aura/drawer";
import editor from "@primeng/themes/aura/editor";
import fieldset from "@primeng/themes/aura/fieldset";
import fileupload from "@primeng/themes/aura/fileupload";
import floatlabel from "@primeng/themes/aura/floatlabel";
import galleria from "@primeng/themes/aura/galleria";
import iconfield from "@primeng/themes/aura/iconfield";
import iftalabel from "@primeng/themes/aura/iftalabel";
import image from "@primeng/themes/aura/image";
import imagecompare from "@primeng/themes/aura/imagecompare";
import inlinemessage from "@primeng/themes/aura/inlinemessage";
import inplace from "@primeng/themes/aura/inplace";
import inputchips from "@primeng/themes/aura/inputchips";
import inputgroup from "@primeng/themes/aura/inputgroup";
import inputnumber from "@primeng/themes/aura/inputnumber";
import inputotp from "@primeng/themes/aura/inputotp";
import inputtext from "@primeng/themes/aura/inputtext";
import knob from "@primeng/themes/aura/knob";
import listbox from "@primeng/themes/aura/listbox";
import megamenu from "@primeng/themes/aura/megamenu";
import menu from "@primeng/themes/aura/menu";
import menubar from "@primeng/themes/aura/menubar";
import message from "@primeng/themes/aura/message";
import metergroup from "@primeng/themes/aura/metergroup";
import multiselect from "@primeng/themes/aura/multiselect";
import orderlist from "@primeng/themes/aura/orderlist";
import organizationchart from "@primeng/themes/aura/organizationchart";
import overlaybadge from "@primeng/themes/aura/overlaybadge";
import paginator from "@primeng/themes/aura/paginator";
import panel from "@primeng/themes/aura/panel";
import panelmenu from "@primeng/themes/aura/panelmenu";
import password from "@primeng/themes/aura/password";
import picklist from "@primeng/themes/aura/picklist";
import popover from "@primeng/themes/aura/popover";
import progressbar from "@primeng/themes/aura/progressbar";
import progressspinner from "@primeng/themes/aura/progressspinner";
import radiobutton from "@primeng/themes/aura/radiobutton";
import rating from "@primeng/themes/aura/rating";
import ripple from "@primeng/themes/aura/ripple";
import scrollpanel from "@primeng/themes/aura/scrollpanel";
import select from "@primeng/themes/aura/select";
import selectbutton from "@primeng/themes/aura/selectbutton";
import skeleton from "@primeng/themes/aura/skeleton";
import slider from "@primeng/themes/aura/slider";
import speeddial from "@primeng/themes/aura/speeddial";
import splitbutton from "@primeng/themes/aura/splitbutton";
import splitter from "@primeng/themes/aura/splitter";
import stepper from "@primeng/themes/aura/stepper";
import steps from "@primeng/themes/aura/steps";
import tabmenu from "@primeng/themes/aura/tabmenu";
import tabs from "@primeng/themes/aura/tabs";
import tabview from "@primeng/themes/aura/tabview";
import tag from "@primeng/themes/aura/tag";
import terminal from "@primeng/themes/aura/terminal";
import textarea from "@primeng/themes/aura/textarea";
import tieredmenu from "@primeng/themes/aura/tieredmenu";
import timeline from "@primeng/themes/aura/timeline";
import toast from "@primeng/themes/aura/toast";
import togglebutton from "@primeng/themes/aura/togglebutton";
import toggleswitch from "@primeng/themes/aura/toggleswitch";
import toolbar from "@primeng/themes/aura/toolbar";
import tooltip from "@primeng/themes/aura/tooltip";
import tree from "@primeng/themes/aura/tree";
import treeselect from "@primeng/themes/aura/treeselect";
import treetable from "@primeng/themes/aura/treetable";
import virtualscroller from "@primeng/themes/aura/virtualscroller";
var aura_default = {
  ...base,
  components: {
    accordion,
    autocomplete,
    avatar,
    badge,
    blockui,
    breadcrumb,
    button,
    datepicker,
    card,
    carousel,
    cascadeselect,
    checkbox,
    chip,
    colorpicker,
    confirmdialog,
    confirmpopup,
    contextmenu,
    dataview,
    datatable,
    dialog,
    divider,
    dock,
    drawer,
    editor,
    fieldset,
    fileupload,
    iftalabel,
    floatlabel,
    galleria,
    iconfield,
    image,
    imagecompare,
    inlinemessage,
    inplace,
    inputchips,
    inputgroup,
    inputnumber,
    inputotp,
    inputtext,
    knob,
    listbox,
    megamenu,
    menu,
    menubar,
    message,
    metergroup,
    multiselect,
    orderlist,
    organizationchart,
    overlaybadge,
    popover,
    paginator,
    password,
    panel,
    panelmenu,
    picklist,
    progressbar,
    progressspinner,
    radiobutton,
    rating,
    scrollpanel,
    select,
    selectbutton,
    skeleton,
    slider,
    speeddial,
    splitter,
    splitbutton,
    stepper,
    steps,
    tabmenu,
    tabs,
    tabview,
    textarea,
    tieredmenu,
    tag,
    terminal,
    timeline,
    togglebutton,
    toggleswitch,
    tree,
    treeselect,
    treetable,
    toast,
    toolbar,
    virtualscroller,
    tooltip,
    ripple
  }
};
export { aura_default as default };
