import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { ToastNotificationsPortalComponent } from './components/notifications/toast-notifications-portal/toast-notifications-portal.component'
import { PrimeNG } from 'primeng/config'
import { PrimePreset } from '@lib/config/prime-preset.config'
import { Toast } from 'primeng/toast'
import { MessageService } from 'primeng/api'
import { AnalyticsService } from '@lib/services/analytics/analytics.service'

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, ToastNotificationsPortalComponent, Toast],
	template: `
		<router-outlet />
		<app-toast-notifications-portal />
		<p-toast />
	`,
})
export class AppComponent {
	constructor(
		private readonly primeConfig: PrimeNG,
		private analyticsService: AnalyticsService
	) {
		this.primeConfig.theme.set({
			preset: PrimePreset,
			options: {
				darkModeSelector: 'dark-mode',
			},
		})
	}
}
