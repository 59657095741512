import { Routes } from '@angular/router'
import { authGuard, authLoginPageGuard } from '@lib/guards/auth/auth.guard'

export const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./layouts/main/main.component').then((m) => m.MainLayoutComponent),
		loadChildren: () => import('./layouts/main/main.routes').then((m) => m.MainRoutes),
		canActivate: [authGuard],
	},
	{
		path: 'login',
		redirectTo: 'auth/login',
	},
	{
		path: 'auth',
		loadComponent: () => import('./modules/auth/presentation/auth.component').then((m) => m.AuthComponent),
		loadChildren: () => import('./modules/auth/presentation/auth.routes').then((m) => m.AuthRoutes),
		canActivate: [authLoginPageGuard],
	},
	{
		path: '**',
		redirectTo: '',
		pathMatch: 'full',
	},
]
